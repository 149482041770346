<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="140px" class="form-con-center">
      <el-form-item label="团队/平台名称" prop="teamsName">
        <el-input v-model="form.teamsName" style="width:350px"></el-input>
      </el-form-item>
      <el-form-item label="Logo">
        <el-upload
          style="width: 350px;"
          class="avatar-uploader"
          list-type="picture-card"
          :action="url"
          :accept="'.jpg, .jpeg, .png, .gif'"
          :limit="1"
          :before-upload="beforeUpload"
          :on-success="handleUploadSuccess"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="团队简介">
        <el-input type="textarea" style="width:350px" :rows="5" v-model="form.content"></el-input>
      </el-form-item>
      <el-form-item label="是否开通商城">
        <el-switch v-model="form.isShop" style="width: 350px;"></el-switch>
        <br>
      </el-form-item>
      <el-form-item label="商城地址" prop="shopUrl" v-if="form.isShop">
        <el-input
          v-model="form.shopUrl"
          style="width:350px"
          placeholder="请输入第三方商城链接地址,需要自带http://或者https://"
        ></el-input>
      </el-form-item>
      <el-form-item label="团队管理员" prop="userName">
        <el-select v-model="form.userName" placeholder="请选择" style="width:350px">
          <el-option v-for="item in userlist" :key="item.id" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专属公众号">
        <el-switch v-model="form.isApp" style="width: 350px;"> </el-switch>
		<el-upload
		v-if="form.isApp"
		  style="width: 350px;"
		  class="avatar-uploader"
		:action="url"
		:accept="'.jpg, .jpeg, .png, .gif'"
		  :show-file-list="false"
		  :on-success="handleUploadSuccess2"
		  :before-upload="beforeUpload">
		  <img v-if="gzhpic" :src="gzhpic" class="avatar">
		  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		</el-upload>
     
      </el-form-item>
      <el-form-item label="底部申明">
        <el-input v-model="form.bottomStatement" style="width:350px"></el-input>
      </el-form-item>
      <!-- <el-form-item label="背景图设置">
        <div class="bgimg">
          <div>
            <p>医生PC端背景图片</p>
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              action="http://192.168.0.115:9095//aliyun/oss/upload"
              :accept="'.jpg, .jpeg, .png, .gif'"
              :limit="1"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess"
              :file-list="fileList1"
            >
              <i  class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div>
            <p>医生PC端背景图片</p>
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              action="http://192.168.0.115:9095//aliyun/oss/upload"
              :accept="'.jpg, .jpeg, .png, .gif'"
              :limit="1"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess"
              :file-list="fileList2"
            >
              <i  class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div>
            <p>医生PC端背景图片</p>
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              action="http://192.168.0.115:9095//aliyun/oss/upload"
              :accept="'.jpg, .jpeg, .png, .gif'"
              :limit="1"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess"
              :file-list="fileList3"
            >
              <i  class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </el-form-item>-->

      <el-form-item class="el-form-button-center">
        <el-button type="primary" @click="submit('form')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import Global from '@/utils/global'
export default {
  props: ["list", "isShow"],
  data() {
    return {
      userlist: [], //团队管理员列表
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
	  gzhpic: '',
      form: {
        teamsName: "",
        avatar: "",
        content: "",
        isShop: false,
        shopUrl: "",
        userName: "",
        isApp: false,
        bottomStatement: "",
      }, //表单
      rules: {
        teamsName: [
          { required: true, message: "请输入工作室名称", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请选择管理员", trigger: "change" },
        ],
        shopUrl: [
          { required: true, message: "请输入商城地址", trigger: "blur" },
        ],
      },
      url: Global.baseURL + "/aliyun/oss/upload",
    };
  },
  watch: {
    isShow: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.fileList = [];
        this.fileList1 = [];
        this.fileList2 = [];
        this.fileList3 = [];
        console.log(newValue);
        if (!newValue) {
          this.form = {};
          this.form = this.list;
		  if(this.form.isApp==1){
			  this.getsyspic()
		  }
          if (this.list.avatar) {
            this.fileList.push({ url: this.list.avatar });
          }

          this.fileList1.push({ url: this.list.avatar });
          this.fileList2.push({ url: this.list.avatar });
          this.fileList3.push({ url: this.list.avatar });
          this.getuserlist();
          console.log(this.form);
        }
      },
    },
  },
  mounted() {
    // this.creatQrCode()
  },
  methods: {
    getuserlist() {
      this.$http({
        url: this.$http.adornUrl("/teamsManage/list"),
        method: "get",
      }).then((res) => {
        if (res.data.status) {
          this.userlist = res.data.data.map((item) => item.userName);
        }
      });
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateteams();
		  if(this.gzhpic.length!=0){
			 this.insertPic(this.gzhpic)
			  	  console.log(this.gzhpic);
		  }
        }
      });
    },
    updateteams() {
      if (this.form.isShop) {
        this.form.isShop = 1;
      } else if (!this.form.isShop) {
        this.form.isShop = 2;
        this.form.shopUrl = "";
      }
      if (this.form.isApp) {
        this.form.isApp = 1;
      } else if (!this.form.isApp) {
        this.form.isApp = 2;
      }
      console.log(this.form);
      this.$http({
        url: this.$http.adornUrl("/teamsManage/update"),
        method: "post",
        params: {
          ...this.form,
        },
      }).then((res) => {
        if (res.data.status) {
          this.$message({
            type: "success",
            message: "成功",
            duration: 1000,
            onClose: () => {
              console.log(111);
              this.$emit("submit", true);
            },
          });
        }
      });
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过10MB");
      }
    },
    handleUploadSuccess(response, file, fileList) {
      console.log(file);
      this.form.avatar = file.response.result.filePath;
    },
	handleUploadSuccess2(response, file) {

	this.gzhpic =  file.response.result.filePath;
	//  this.insertPic(file.response.result.filePath) 

	},
	insertPic(picUrl) {
	  let that = this;
	  let params = {
	    actionId: JSON.parse(localStorage.currentTeam).id  ,
	    picType: 31, //慢病上传文件类型
	    picUrl: picUrl,
	    fileName: 'gzhpic'
	  };
	  that
	    .$http({
	      url: that.$http.adornUrl("/sysPic/insertPic"),
	      data: JSON.stringify(params),
	      method: "post",
	      headers: {
	        "Content-Type": "application/json",
	        token: this.$cookie.get("token")
	      }
	    })
	    .then(({ data }) => {
	      if (data.status) {
	        
	      }
	    });
	},
	getsyspic() {
	  let that = this;
	  let params = {
	    actionId: JSON.parse(localStorage.currentTeam).id  ,
	    picType: 31, //慢病上传文件类型
	  
	  };
	  that
	    .$http({
	     url: that.$http.adornUrl("/sysPic/listPic"),
	     params: params,
	      method: "get",
	      headers: {
	        "Content-Type": "application/json",
	        token: this.$cookie.get("token")
	      }
	    })
	    .then(({ data }) => {
	      if (data.status) {
	        this.gzhpic = data.data[0].picUrl
	      }
	    });
	},
    //生成二维码
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: "https://www.baidu.com/", // 二维码的内容
        width: 100,
        height: 100,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #0476F5;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-input {
  width: 30%;
}
.bgimg {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
</style>